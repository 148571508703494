<template>
  <div>
    <div class="main-title">问题解答</div>
    <div class="content-list-page page-content">
      <div class="list-head-box">
        <div data-v-69874ad0="" class="list-head">
          <p data-v-69874ad0="" class="list-head-title">温馨提示</p>
          <!-- <ul data-v-69874ad0="">
						<li data-v-69874ad0="">
							点评后不能修改，请老师点评前先仔细检查点评内容是否存在错别字
						</li>
						<li data-v-69874ad0="">点评完成后系统将自动推送消息到用户微信上</li>
						<li data-v-69874ad0="">
							作业点评为优秀时，学生可获得100学币奖励，学币可以用于下载资料以及兑换商品
						</li>
						<li data-v-69874ad0="">
							附件可以上传例文或者修改后的作业图，不能超过10M
						</li>
					</ul> -->
          *****
        </div>
      </div>

      <div style="position: relative; margin-bottom: 10px">
        <div class="margin-l-sm fl-l margin-t-sm" style="margin-top: 8px">
          <el-input
            v-model="searchData.questioner"
            placeholder="提问者"
          ></el-input>
        </div>

        <div class="margin-l-sm fl-l margin-t-sm" style="margin-top: 8px">
          <el-input
            v-model="searchData.answerer"
            placeholder="解答者"
          ></el-input>
        </div>

        <div class="margin-l-sm fl-l margin-t-sm" style="margin-top: 8px">
          <!-- <el-input
            v-model="searchData.courseName"
            placeholder="课程名称"
          ></el-input> -->
          <el-select
            v-model="searchData.courseName"
            placeholder="课程名称"
            size="mini"
          >
            <el-option
              v-for="item in options"
              :key="item.id"
              :label="item.name"
              :value="item.name"
            >
            </el-option>
          </el-select>
        </div>
        <div class="margin-l-sm fl-l margin-t-sm" style="margin-top: 8px">
          <el-input
            v-model="searchData.planName"
            placeholder="计划名称"
          ></el-input>
        </div>

        <span class="margin-l-sm fl-l margin-t-sm">
          <el-select
            v-model="searchData.status"
            clearable
            placeholder="作业类型"
            size="small"
          >
            <el-option label="未解答" :value="0"></el-option>
            <el-option label="已解答" :value="1"></el-option>
          </el-select>
        </span>

        <span class="margin-l-sm fl-l margin-t-sm">
          <el-date-picker
            size="small"
            v-model="searchData.datePick"
            type="daterange"
            align="right"
            value-format="yyyy-MM-dd"
            unlink-panels
            range-separator="至"
            start-placeholder="点评日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
        </span>

        <span class="margin-l-sm fl-l margin-t-sm">
          <el-button
            type="primary"
            icon="el-icon-search"
            size="small"
            @click="search"
            >搜索</el-button
          >
        </span>
      </div>

      <div class="table-content">
        <el-table
          :header-cell-style="{ padding: 0, 'text-align': 'center' }"
          :cell-style="{ 'text-align': 'center' }"
          ref="singleTable"
          :data="tableData"
          highlight-current-row
          v-loading="loading"
          style="width: 100%; background: #fff"
        >
          <el-table-column label="ID" property="id" width="80">
          </el-table-column>
          <el-table-column label="提问人" property="questioner">
          </el-table-column>
          <el-table-column label="解答人" property="answerer">
          </el-table-column>
          <el-table-column label="课程名称" property="course_name">
          </el-table-column>
          <el-table-column label="计划名称" property="plan_name">
          </el-table-column>
          <el-table-column property="question" label="问题内容">
          </el-table-column>

          <el-table-column width="150" label="图片/视频">
            <template slot-scope="scope">
              <div v-if="scope.row.pic_attachments.length > 0">
                <el-image
                  style="width: 100px; height: 100px"
                  :src="imageUrl + scope.row.pic_attachments[0]"
                  :preview-src-list="images(scope.row.pic_attachments)"
                >
                </el-image>
              </div>
              <div v-else>
                <!-- <video :src="videoUrl + scope.row.video_attachment"></video> -->
              </div>
            </template>
          </el-table-column>

          <el-table-column property="ask_at" label="提问时间">
          </el-table-column>
          <el-table-column property="answered_at" label="解答时间">
          </el-table-column>

          <el-table-column property="address" label="状态">
            <template slot-scope="scope">
              <span v-if="scope.row.status == 0" class="state-down"
                ><i></i>未解答</span
              >
              <span v-if="scope.row.status == 1" class="state-up"
                ><i></i>已解答</span
              >
            </template>
          </el-table-column>
          <el-table-column property="address" label="操作">
            <template slot-scope="scope">
              <span v-if="scope.row.status == 0">
                <span>
                  <el-link
                    type="primary"
                    v-if="scope.row.status == 0"
                    @click="comment(scope.row, scope.$index)"
                    >解答</el-link
                  >
                </span>
              </span>
              <span v-else>
                <el-link type="primary" @click="view(scope.row, scope.$index)"
                  >查看解答</el-link
                >
              </span>
            </template>
          </el-table-column>
        </el-table>
      </div>

      <div class="table-batch">
        <span class="fl-r">
          <el-pagination
            small
            background
            :current-page="page"
            :page-sizes="[10, 20]"
            :page-size="pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            @size-change="setPageSize"
            @current-change="setPage"
            :total="total"
          >
          </el-pagination>
        </span>
        <div style="clear: both"></div>
      </div>

      <el-dialog
        title="解答"
        :visible.sync="answerDialogVisible"
        append-to-body
        width="90%"
      >
        <div class="answerBox">
          <div class="question">
            <div>
              提问人：<span style="color: #000; font-size: 16px">{{
                question.questioner
              }}</span>
            </div>
            <div>
              提问内容：<span style="color: #000; font-size: 16px">{{
                question.question
              }}</span>
            </div>
            <div class="picList" v-if="question.pic_attachments != ''">
              <div v-for="item in question.pic_attachments" :key="item">
                <el-image
                  style="width: 100px; height: 100px"
                  :src="imageUrl + item"
                  :preview-src-list="images(question.pic_attachments)"
                >
                </el-image>
              </div>
            </div>
            <div
              class="video"
              v-else-if="
                answerContent.pic_attachments == '' &&
                answerContent.video_attachment != ''
              "
            >
              <video-player ref="video"></video-player>
            </div>
          </div>
          <div class="answer">
            <div class="answer-answer">
              <el-input
                type="textarea"
                placeholder="请输入解答"
                v-model="answer.answer"
              ></el-input>
            </div>
            <div class="answer-answer">
              <video-upload @success="onVideoSuccess"></video-upload>
            </div>
            <div class="answer-answer">
              <paste-image
                upload-path="question"
                @onSuccess="onSuccess"
                @onRemove="onRemove"
              ></paste-image>
            </div>
            <div class="answer-answer">
              <recorder @on-success="onRecordSuccess"></recorder>
            </div>
          </div>
        </div>
        <div class="button">
          <el-button @click="answerDialogVisible = false">关闭</el-button>
          <el-button type="primary" @click="submit" :loading="buttonLoading"
            >提交</el-button
          >
        </div>
      </el-dialog>

      <el-dialog
        title="查看解答"
        :visible.sync="lookAnswerDialogVisible"
        append-to-body
        width="60%"
      >
        <div class="lookAnswer" v-loading="lookAnswerLoading">
          <div class="answerContent-content">
            解答内容：<span style="color: #000; font-size: 16px">{{
              answerContent.answer
            }}</span>
          </div>
          <div class="picList" v-if="answerContent.pic_attachments != ''">
            <div v-for="item in answerContent.pic_attachments" :key="item">
              <el-image
                style="width: 100px; height: 100px"
                :src="imageUrl + item"
                :preview-src-list="images(answerContent.pic_attachments)"
              >
              </el-image>
            </div>
          </div>
          <div
            v-else-if="
              answerContent.pic_attachments == '' &&
              answerContent.video_attachment != ''
            "
          >
            <video-player ref="answerVideo"></video-player>
          </div>
          <div
            style="margin-top: 20px"
            v-if="answerContent.audio_attachment != ''"
          >
            <audio
              :src="imageUrl + answerContent.audio_attachment"
              controls
            ></audio>
          </div>
        </div>
        <div class="button">
          <el-button @click="lookAnswerDialogVisible = false">关闭</el-button>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import videoPlayer from "@/components/video.vue";
import PasteImage from "@/components/pasteImage/index";
import Recorder from "@/components/recorder/index";
import videoUpload from "@/components/uploadVideo/index";
export default {
  name: "list",
  components: {
    videoPlayer,
    PasteImage,
    Recorder,
    videoUpload,
  },
  data() {
    return {
      options: [],
      searchData: {},
      loading: false,
      tableData: [],
      page: 1,
      pageSize: 10,
      total: 0,
      imageUrl: config.imageUrl,
      buttonLoading: false,
      answerDialogVisible: false,
      question: {},
      answer: {},
      videoUrl: "https://video.xxyw100.com/",
      lookAnswerDialogVisible: false,
      lookAnswerLoading: false,
      answerContent: {},
    };
  },
  methods: {
    ...mapActions("quiz", [
      "listAskQuestion",
      "showAnswer",
      "selectCheckCourse",
    ]),
    ...mapActions("common", ["answerQuestion"]),
    async submit() {
      this.buttonLoading = true;
      let answer = JSON.parse(JSON.stringify(this.answer));
      const { data, res_info } = await this.answerQuestion(answer);
      this.buttonLoading = false;
      this.answerDialogVisible = false;
      if (res_info != "ok") {
        this.$message.error("失败");
      } else {
        this.$message.success("解答成功");
      }
      this.initData();
    },
    onSuccess(path) {
      this.answer.picAttachments = path;
    },
    onVideoSuccess(data) {
      this.answer.videoAttachment = data.url;
    },
    onRecordSuccess(path) {
      this.answer.audioAttachment = path;
    },
    onRemove(index, file) {
      this.answer.picAttachments.splice(index, 1);
    },
    images(imgList) {
      let arr = [];
      imgList.forEach((c) => {
        arr.push(this.imageUrl + c);
      });
      return arr;
    },
    async view(val, index) {
      this.lookAnswerLoading = true;
      this.lookAnswerDialogVisible = true;
      let form = { questionId: val.id };
      const { data } = await this.showAnswer(form);
      this.answerContent = data;
      if (val.pic_attachments == "") {
        await this.$nextTick(() => {
          console.log(val.video_attachment.indexOf("video"));
          if (val.video_attachment.indexOf("video") == -1) {
            this.$refs.answerVideo.playerOptions["sources"][0]["src"] =
              this.videoUrl + val.video_attachment;
          } else {
            this.$refs.answerVideo.playerOptions["sources"][0]["src"] =
              this.imageUrl + val.video_attachment;
          }
        });
      }
      this.lookAnswerLoading = false;
    },
    comment(val, index) {
      this.answer = {};
      this.answerDialogVisible = true;
      this.question = val;
      this.answer.userQuestionsId = val.id;
      if (val.pic_attachments == "") {
        this.$nextTick(() => {
          this.$refs.answerVideo.playerOptions["sources"][0]["src"] =
            this.imageUrl + data.video_attachment;
        });
      }
    },
    setPageSize(pageSize) {
      this.pageSize = pageSize;
      this.initData();
    },
    setPage(page) {
      this.page = page;
      this.initData();
    },
    search() {
      this.page = 1;
      this.initData();
    },
    async initData() {
      this.loading = true;
      let from = {
        ...this.searchData,
        page: this.page,
        pageSize: this.pageSize,
      };
      const { data } = await this.listAskQuestion(from);
      const res = await this.selectCheckCourse();
      this.options = res.data;
      console.log(res);
      this.tableData = data.list;
      this.total = data.total;
      console.log(this.tableData);
      this.loading = false;
    },
  },
  mounted() {
    this.initData();
  },
};
</script>

<style>
.selectPic {
  position: relative;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 40px;
  overflow: hidden;
}

.selectPic p {
  float: left;
  width: 20%;
  text-align: center;
  line-height: 40px;
  cursor: pointer;
}

.selectPic p:first-child {
  cursor: default;
}
.answerBox {
  width: 100%;
  display: flex;
}
.question,
.answer {
  width: 50%;
  line-height: 26px;
}
.question {
  border-right: 1px solid #000;
}
.picList {
  width: 90%;
  margin: 20px auto;
  display: flex;
  flex-wrap: wrap;
}

.picList div {
  width: 33%;
}
.video {
  width: 90%;
  margin: 20px auto;
}
.answer-answer {
  width: 90%;
  margin: 10px auto;
}
.button {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0px;
}
.lookAnswer {
  width: 100%;
}
.answerContent-content {
  margin: 10px 0px;
}
</style>
