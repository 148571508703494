<template>
    <div>
        <div v-if="data !== ''" style="font-size: 12px">文件已上传：{{data}}</div>

        <p style="font-size: 12px; color: #999; margin-bottom: 7px;">
            <slot name="prompt">视频上传，文件小于1G</slot>
        </p>

        <el-upload ref="fileList" multiple :action="uploadUrl" :class="hideUploadClass" :limit="uploadNumber"
            :on-change="onChange" :accept="accept" :data="uploadData" :on-success="onSuccess" :on-remove="onRemove"
             :on-exceed="onExceed"
            :file-list="fileList" :before-upload="beforeUpload" :auto-upload="true">
            <el-button slot="default" size="mini" type="primary" v-if="data === ''">选择文件</el-button>
            <el-button slot="default" size="mini" v-if="data !== ''">重新上传</el-button>

        </el-upload>
    </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
    components: {
    },
    props: {
        uploadPath: {
            default: 'video',
            type: String
        },
        uploadNumber: {
            default: 10,
            type: Number
        },
        accept: {
            default: '.mp4, .ogv, .ogg, .webm',
            type: String
        },
        data: {
            default: '',
            type: String
        }
    },
    name: "mediaUpload",
    data() {
        return {
            uploadUrl: '',
            fileList: [],
            dialogImageUrl: '',
            dialogVisible: false,
            disabled: false,
            hideUploadClass: '',
            uploadData: {},
            fileName: '',
            path: '',
            realName:[]
        };
    },
    methods: {
        ...mapActions('common', ['getOssInfo']),
        handleRemove() {
            this.fileList = []
            this.hideUploadClass = ''
        },
        handlePictureCardPreview(file) {
            this.dialogImageUrl = file.url;
            this.dialogVisible = true;
        },
        onChange(file, fileList) {
            let fileName = file.name

            if (fileList.length >= this.uploadNumber) {
                this.hideUploadClass = 'hide'
            } else {
                this.hideUploadClass = ''
            }
            this.fileName = fileName

        },
        onRemove(file, fileList) {
            console.log(file)
            console.log(fileList)
        },
        onExceed(file) {
            console.log(file)
        },
        async beforeUpload(file) {
            let _this = this
            let time = (new Date()).valueOf()
            let url = `video/${time}.mp4`
            this.realName.push({
                url:url,
                name:file.name
            })
            await this.getOssInfo(this.uploadPath).then(res => {
                this.uploadUrl = res.data.host
                _this.path =  _this.fileName

                let data = {
                    key: url,
                    policy: res.data.policy,
                    OSSAccessKeyId: res.data.accessid,
                    signature: res.data.signature,
                    callback: res.data.callback,
                }
                _this.uploadData = data
            })
        },

        // 文件上传成功
        async onSuccess(res) {
            this.realName.forEach(item => {
                if(item.url === res.data.path){
                    this.$notify({
                        title: '上传成功',
                        type: 'success',
                        message: '文件上传成功',
                    });
                    let data = {
                        name: item.name,
                        format: res.data.mimeType,
                        url: res.data.path,
                        size: res.data.size,
                    }
                    this.$emit('success', data)
                }
            })
        },
    },
    mounted() {
        this.$nextTick(function () {
            this.$on('reset', function () {
                this.uploadUrl = ''
                this.fileList = []
                this.dialogImageUrl = ''
                this.dialogVisible = false
                this.disabled = false
                this.hideUploadClass = ''
                this.uploadData = {}
                this.fileName = ''
                this.path = ''
            });
        });
    }
}
</script>
